<template>
  <div v-lazy-background class="w-full one-stop-roofing" data-bg="/one-stop-roofing-cta-banner.webp">
    <div class="w-full h-full flex flex-col items-center">
      <div class="text-center mt-16 p-5">
        <span class="text-white lg:text-[70px] text-[40px] font-medium font-['Montserrat'] capitalize lg:leading-[85px] leading-[50px]">
          Your One-stop Roofing<br> Shop in</span>
        <span class="text-orange-600 lg:text-[70px] text-[40px] font-medium font-['Montserrat'] capitalize lg:leading-[85px] leading-[50px]">
          Georgia
        </span>
      </div>
      <div class="text-center mx-auto xl:w-2/5 lg:w-3/5 md:w-4/5 lg:mt-10 mt-0 p-5">
        <span class="text-white text-lg font-normal font-['Montserrat'] lg:leading-[35px] leading-7">
          Whether you’re in need of a roof replacement or a roof for a new construction,
          our team has a solution that’s just right for you. Give us a call at<br>
        </span>
        <span class="text-white text-xl font-bold font-['Montserrat'] lg:leading-[35px] leading-7">
          {{ phoneNumber }}
        </span>
        <span class="text-white text-lg font-normal font-['Montserrat'] lg:leading-[35px] leading-7">
          to discuss your needs with a roofing expert that can devise a roofing plan that fits your stylistic
          preferences and budget. We look forward to working with you.
        </span>
      </div>
      <div class="text-center mx-auto sm:w-2/5 w-full lg:mt-10 mt-0 p-5">
        <span class="text-rose-100 lg:text-[35px] text-2xl font-medium font-['Montserrat'] capitalize leading-[41px]">
          Schedule Your Free
        </span>
        <span class="text-orange-600 lg:text-[35px] text-2xl font-bold font-['Montserrat'] capitalize leading-[41px]">
          Estimate Today!
        </span>
      </div>
      <div class="flex justify-center h-[60px] lg:mt-10 mt-0 mb-16">
        <CommonButtonsEstimate text="Free Estimate" :icon="true" button-classes="bg-orange-600" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { usePhoneNumber } from '@/composables/usePhoneNumber'

const phoneNumber = usePhoneNumber()
</script>

<style scoped>
.one-stop-roofing {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.one-stop-roofing>div {
  background-color: rgba(21, 19, 19, 0.7)
}
</style>
